<template>
  <div class="msg-center">
    <div class="top df aic jcsb mb40" style="display: flex;justify-content: center;align-items: center;">
      <p style="font-weight: bold;font-size: 16px;">消息中心<span>（99）</span></p>
      <div>
        <!-- <i class="iconfont icon-shuazi font-size-38 mr20"></i> -->
        <!-- <i class="iconfont icon-shezhi font-size-38"></i> -->
      </div>
    </div>
    <div class="box bsbb">
      <!-- <div class="nav df aic jcsa fw5 mt10">
        <div class="df aic jcc fdc mb20">
          <div class="one df aic jcc">
            <i class="iconfont icon-shangpu"></i>
          </div>
          <p class="font-size-28 mt20">商家群</p>
        </div>
        <div class="df aic jcc fdc mb20">
          <div class="two df aic jcc fdc">
            <i class="iconfont icon-zuanshi"></i>
          </div>
          <p class="font-size-28 mt20">权益通知</p>
        </div>
        <div class="df aic jcc fdc mb20">
          <div class="tree df aic jcc fdc">
            <i class="iconfont icon-laba-"></i>
          </div>
          <p class="font-size-28 mt20">服务消息</p>
        </div>
        <div class="df aic jcc fdc mb20">
          <div class="four df aic jcc fdc">
            <i class="iconfont icon-piaowu"></i>
          </div>
          <p class="font-size-28 mt20">优惠活动</p>
        </div>
      </div> -->
      <div class="content df fdc">
        <div class="car df aic jcsb mb40">
          <div class="df aic jcc">
            <div class="icon df aic jcc mr20">
              <i class="iconfont icon-diandongche"></i>
            </div>
            <div>
              <p class="font-size-24 fw7 mb10">骑手已取货</p>
              <p class="font-size-20">您的订单已从菜大全果蔬成都店取出</p>
            </div>
          </div>
          <div class="font-size-20">2023/01/31</div>
        </div>
        <div class="car df aic jcsb">
          <div class="df aic jcc">
            <div class="icon df aic jcc mr20">
              <i class="iconfont icon-24gl-box"></i>
            </div>
            <div>
              <p class="font-size-24 fw7 mb10">配送完成</p>
              <p class="font-size-20">您的菜大全果蔬成都店订单已配送完成</p>
            </div>
          </div>
          <div class="font-size-20">2023/01/31</div>
        </div>
        <div class="car df aic jcsb mt40" style="margin-bottom: 20px;">
          <div class="df aic jcc">
            <div class="icon df aic jcc mr20">
              <!-- <i class="iconfont icon-24gl-box"></i> -->
              <img src="../../assets/images/xg/preferred/110.png" alt="" style="width: 80%;">
            </div>
            <div>
              <p class="font-size-24 fw7 mb10">骑手消息</p>
              <p class="font-size-20">你的货品已经放到小区门口了</p>
            </div>
          </div>
          <div class="font-size-20">2023/01/31</div>
        </div>

        <div class="cars df aic jcsb mb40">
          <div class="df aic jcc">
            <div class="icon df aic jcc mr20">
              <img src="../../assets/images/xg/list5.png" />
            </div>
            <div>
              <p class="font-size-24 fw7 mb10">嗨你好吗</p>
              <p class="font-size-20">好的，谢谢！</p>
            </div>
          </div>
          <div class="font-size-20">2023/01/31</div>
        </div>
        <div class="cars df aic jcsb mb40">
          <div class="df aic jcc">
            <div class="icon df aic jcc mr20">
              <img src="../../assets/images/xg/list5.png" />
            </div>
            <div>
              <p class="font-size-24 fw7 mb10">王大妈水果店</p>
              <p class="font-size-20">好的，谢谢！</p>
            </div>
          </div>
          <div class="font-size-20">2023/01/31</div>
        </div>
        <div class="cars df aic jcsb mb40">
          <div class="df aic jcc">
            <div class="icon df aic jcc mr20">
              <img src="../../assets/images/xg/list5.png" />
            </div>
            <div>
              <p class="font-size-24 fw7 mb10">欢乐水果店</p>
              <p class="font-size-20">好的，谢谢！</p>
            </div>
          </div>
          <div class="font-size-20">2023/01/31</div>
        </div>
        <div class="cars df aic jcsb mb40">
          <div class="df aic jcc">
            <div class="icon df aic jcc mr20">
              <img src="../../assets/images/xg/list5.png" />
            </div>
            <div>
              <p class="font-size-24 fw7 mb10">水果天地</p>
              <p class="font-size-20">好的，谢谢！</p>
            </div>
          </div>
          <div class="font-size-20">2023/01/31</div>
        </div>
      </div>
    </div>

    <!-- <div class="fruit-list">
      <div class="list df aic jcsb">
        <div class="df aic jcc mb40">
          <img src="../../assets/images/xg/list5.png" class="mr20" />
          <div class="df fdc">
            <div class="df aic jcsb" style="width: 275px">
              <p class="font-size-30 fw7">菜大全鲜果蔬菜成都店</p>
              <div class="font-size-24">12:24</div>
            </div>
            <p class="font-size-26 mt20">好的,谢谢!</p>
          </div>
        </div>
      </div>
      <div class="list df aic jcsb">
        <div class="df aic jcc mb40 mt20">
          <img src="../../assets/images/xg/list1.png" class="mr20" />
          <div class="df fdc">
            <div class="df aic jcsb" style="width: 275px">
              <p class="font-size-30 fw7">菜大全鲜果蔬菜成都店</p>
              <div class="font-size-24">12:24</div>
            </div>
            <p class="font-size-26 mt20">好的,谢谢!</p>
          </div>
        </div>
      </div>
      <div class="list df aic jcsb">
        <div class="df aic jcc mb40 mt20">
          <img src="../../assets/images/xg/list2.png" class="mr20" />
          <div class="df fdc">
            <div class="df aic jcsb" style="width: 275px">
              <p class="font-size-30 fw7">菜大全鲜果蔬菜成都店</p>
              <div class="font-size-24">12:24</div>
            </div>
            <p class="font-size-26 mt20">好的,谢谢!</p>
          </div>
        </div>
      </div>
      <div class="list df aic jcsb">
        <div class="df aic jcc mb40 mt20">
          <img src="../../assets/images/xg/list4.png" class="mr20" />
          <div class="df fdc">
            <div class="df aic jcsb" style="width: 275px">
              <p class="font-size-30 fw7">菜大全鲜果蔬菜成都店</p>
              <div class="font-size-24">12:24</div>
            </div>
            <p class="font-size-26 mt20">好的,谢谢!</p>
          </div>
        </div>
      </div>
      <div class="list df aic jcsb">
        <div class="df aic jcc mb40 mt20">
          <img src="../../assets/images/xg/list3.png" class="mr20" />
          <div class="df fdc">
            <div class="df aic jcsb" style="width: 275px">
              <p class="font-size-30 fw7">菜大全鲜果蔬菜成都店</p>
              <div class="font-size-24">12:24</div>
            </div>
            <p class="font-size-26 mt20">好的,谢谢!</p>
          </div>
        </div>
      </div>
      <div class="later mt40">
        <div class="timing">两周之前</div>
        <div class="list df aic jcsb mt40">
          <div class="df aic jcc mb20 mt20">
            <img src="../../assets/images/xg/list4.png" class="mr20" />
            <div class="df fdc">
              <div class="df aic jcsb" style="width: 275px">
                <p class="font-size-30 fw7">菜大全鲜果蔬菜成都店</p>
                <div class="font-size-24">12:24</div>
              </div>
              <p class="font-size-26 mt20">好的,谢谢!</p>
            </div>
          </div>
        </div>
        <div class="list df aic jcsb mt40">
          <div class="df aic jcc mb20 mt20">
            <img src="../../assets/images/xg/list11.png" class="mr20" />
            <div class="df fdc">
              <div class="df aic jcsb" style="width: 275px">
                <p class="font-size-30 fw7">菜大全鲜果蔬菜成都店</p>
                <div class="font-size-24">12:24</div>
              </div>
              <p class="font-size-26 mt20">好的,谢谢!</p>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 100px"></div>
    </div> -->
  </div>
</template>

<script setup>
</script>

<style scoped lang="scss">
@import '../../assets/css/font-face.css';
.msg-center {
  font-family: pingfangs;
  padding: 20px;

  .top {
    font-size: 40px;

    span {
      font-size: 24px;
    }
  }

  .box {
    margin-top: 30px;
    width: 690px;
    // height: 720px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);
    margin: 0 auto;

    .nav {
      margin-bottom: 20px;

      > div {
        > div {
          width: 84px;
          height: 84px;
          opacity: 1;
          border-radius: 50%;
          text-align: center;
          color: #fff;

          i {
            font-size: 50px;
          }
        }
      }

      .one {
        background: linear-gradient(
          131.64deg,
          rgba(0, 183, 255, 1) 0%,
          rgba(63, 202, 255, 1) 100%
        );
      }

      .two {
        background: linear-gradient(
          228.21deg,
          rgba(255, 183, 1, 1) 0%,
          rgba(251, 185, 1, 1) 100%
        );
      }

      .tree {
        background: linear-gradient(
          102.16deg,
          rgba(90, 206, 19, 1) 0%,
          rgba(88, 207, 18, 1) 100%
        );
      }

      .four {
        background: linear-gradient(
          240.01deg,
          rgba(255, 133, 35, 1) 0%,
          rgba(254, 112, 2, 1) 100%
        );
      }
    }

    .content {
      padding: 20px 20px 40px;
      img{
        border-radius: 20px;
      }
      > div {
        // border-top: 2px solid rgb(245, 241, 241);
        padding-top: 40px;
      }

      .car {
        .icon {
          width: 72px;
          height: 72px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(95, 195, 104, 1);
        }

        i {
          font-size: 80px;
          color: #fff;
        }
        .font-size-24{
          font-size: 32px !important;
        }
        .icon-24gl-box {
          font-size: 46px;
        }
      }
      .cars {
        
        .icon {
          width: 72px;
          height: 72px;
          opacity: 1;
          border-radius: 20px;
        }

        i {
          font-size: 80px;
          color: #fff;
        }
        .font-size-24{
          font-size: 32px !important;
        }
        .icon-24gl-box {
          font-size: 46px;
        }
      }
    }
  }

  .fruit-list {
    // margin-top: 40px;
    padding: 20px;

    .list {
      img {
        width: 100px;
        height: 100px;
        border-radius: 8px;
      }
    }

    .later {
      padding-top: 40px;
      border-top: 1px solid #ddd;
      position: relative;

      .timing {
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        z-index: 100;
        width: 100px;
        background: #f8f8f8;
        padding: 20px;
      }
    }
  }
}
</style>